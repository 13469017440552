.toogle {
  margin-bottom: 15px;

  p {
    &.toogle-label {
      color: $text-color;
      padding-right: 10px;
      margin-bottom: 0;
      line-height: 18px;
      font-size: 14px; } }

  .toogle-swicth {
    width: 61px;

    .custom-switch {
      .custom-control-label {
        &::before {
          height: 1.8rem;
          width: 3.8rem;
          border-radius: 1.5rem;
          background-color: #9A9DA5;
          border: 1px solid #9A9DA5; }

        &::after {
          top: calc(.25rem + 4px);
          left: calc(-2.25rem + 4px);
          width: calc(1.5rem - 4px);
          height: calc(1.5rem - 4px);
          background-color: $white-color;
          border-radius: .8rem; } }

      .custom-control-input:checked~.custom-control-label::after {
        background-color: #fff;
        transform: translateX(1.9rem); }

      .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #00A4B3;
        background-color: #00A4B3; }

      .custom-control-input:focus:not(:checked)~.custom-control-label::before,
      .custom-control-input:focus~.custom-control-label::before {
        box-shadow: none; } }

    .off {
      z-index: 1;
      position: absolute;
      font-size: 12px;
      color: white;
      top: .6rem;
      right: 0; } } }

