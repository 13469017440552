/* Wizard */
.wizard {
  margin-right: 25px;

  .wizard-header {
    height: 48px;
    margin-bottom: 50px;

    .logos {
      width: calc( 50% - 10px );
      margin-right: 30px;

      &:last-child {
        margin-right: 0; }

      img {
        height: auto;
        width: 100%;
        /* padding-right: 10px */ } } } }

.wizard-flow {
  height: 300px;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a8ffffff',GradientType=0 );

  h6 {
    margin-bottom: 40px;
    line-height: 30px;
    color: #999ca5;

    &.active {
      color: #7dc281; } }

  .wizard-component {
    margin-left: 40px;
    z-index: 0;

    #progressbar {
      position: absolute;
      left: -40px;
      overflow: hidden;
      color: #7dc281;

      li {
        position: relative;
        list-style-type: none;
        font-size: 8px;
        font-weight: 400;
        margin-bottom: 40px;

        &:before {
          width: 30px;
          height: 30px;
          line-height: 30px;
          display: block;
          font-size: 20px;
          background: #fff;
          border: 3px solid #999ca5;
          border-radius: 50%;
          margin: auto; }

        &:after {
          content: '';
          width: 3px;
          height: 46px;
          background: #999ca5;
          position: absolute;
          left: 14px;
          top: 34px;
          z-index: 0; }

        &:last-child:after {
          top: 147px;
          height: 132px; }

        &.active {
          &:after {
            background: #7dc281; }

          &:before {
            background: #7dc281;
            font-family: FontAwesome;
            content: "\f00c";
            font-size: 16px;
            top: -12px;
            line-height: 26px;
            border: 3px solid #7dc281; } } }

      .step0 {
        &:before {
          content: "";
          color: #fff; } } } } }
