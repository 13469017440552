body {
    font-family: 'Nunito Sans', sans-serif; }

.title-page {
    font-size: 25px;
    font-weight: bold; }

.description {
    font-size: 1rem;
    line-height: 20px;
    color: $gray-color1; }
