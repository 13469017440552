.metadata-page {
  margin-top: 30px;

  label {
    font-weight: bold; }

  input {
    &.metadata-input {
      max-width: 150px;
      padding: 10px;
      margin: 0 10px;
      text-align: center; } }

  .metadata-inpu-label {
    max-width: 34px;
    font-size: 14px;
    display: flex;
    margin: 0; }

  .metadata-input-time {
    // max-width: 34px
    font-size: 14px;
    display: flex;
    margin: 0; }

  .minimo-carrito {
    margin-bottom: 15px; }

  .accordion-container {
    margin-bottom: 100px; } }


