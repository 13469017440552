
body {
  // background-color: red
  font-family: 'Roboto', sans-serif;
  background-image: url('../../assets/img/body-bkg-pattern.png');
  background-repeat: repeat;
  background-color: #e6e6e6;
  font-size: 1rem;
  padding-top: 5vh; }

.flow-container {
  border-radius: 20px;
  min-height: 90vh;
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 35px;
  background-image: url('../../assets/img/graphic.svg');
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 35%;
  margin-bottom: 30px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.3);

  &.onboarding {
    .container-fluid {
      // background-image: url('../assets/img/iso.png')
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 16%;
      min-height: 90vh; } }

  .left-col {
    min-height: 90vh;
    padding-top: 20px;
    -webkit-box-shadow: 3px 0px 3px -1px rgba(0,0,0,0.2);
    box-shadow: 3px 0px 3px -1px rgba(0,0,0,0.2); }

  .container-fluid {
    padding: 0;

    .page-container {
      margin-left: 40px;
      min-height: calc( 90vh - 80px); } } }


.citibanamex-true {
  transition: .5s !important;
  font-weight: 600;
  color: black !important; }
.citibanamex-false {
  transition: .5s !important;
  font-weight: 400;
  color: gray !important; }
.banamex-container {
  background-color: none !important;
  margin-top: 2rem !important;
  left: .5rem; }
