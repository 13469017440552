body {
    height: 100vh; }

#root {
    height: 100%; }

#transaction-pending {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
        padding: 4rem 2.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        .icon {
            height: 10em;
            width: 10em;
            background-color: $primary_color;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: rotateClock 4s ease infinite;

            svg {
                font-size: 4rem;
                color: #fff; } }

        .title {
            font-size: 1.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 600;
            margin-top: 2rem; }
        .subtitle {
            margin-top: 1em;
            font-size: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center; } } }

@keyframes rotateClock {
    0% {
        transform: rotateZ(0deg); }
    49% {
        transform: rotateZ(180deg); }
    70% {
        transform: rotateZ(180deg); }
    90% {
        transform: rotateZ(180deg); }
    100% {
        transform: rotateZ(0deg); } }
