.form-group {
  position: relative;

  i.icon-input {
    position: absolute;
    top: 46px;
    left: 12px;
    z-index: 1;
    color: $input-color; } }

.form-control {
  position: relative;
  border: 0.5px solid $input-color;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 10px 10px 36px;

  &::-moz-placeholder {
    color: $input-color!important; }

  &::-ms-input-placeholder {
    color: $input-color!important; }

  &::-webkit-input-placeholder {
    color: $input-color!important; } }

label {
  font-size: 18px; }

.text-muted {
  font-size: 12px;
  padding: 8px;
  background-color: #D0E5FD;
  background-color: rgba(208, 229, 253, .15);
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-top: .5rem;

  i {
    color: $primary-color; }

  .icon-info {
    margin: 0 5px; }

  p {
    padding: 0 10px;
    margin: 0;
    display: inline-block;
    color: #999ca5;
    line-height: 15px;

    a {
      color: $primary-color;
      text-decoration: underline; } } }

