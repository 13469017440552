.footer {
  position: absolute;
  bottom: 28px;
  width: 100%;
  padding-left: 40px;

  .btn {
    &.btn-primary {
      background-color: $primary-color;
      border-color: $primary-color;
      font-size: 12px;
      // height: 34px
      min-width: 140px;
      border-radius: 8px;
      padding: 12px 24px;
      opacity: 1;
      transition: 0.3s;
      text-align: left;

      i {
        // padding-left: 20px
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: 18px; }

      &:hover {
        background-color: lighten($primary-color, 5%);
        border-color: lighten($primary-color, 5%); }

      &:focus,
      &:active,
      &.active,
      &:active:focus,
      &:active:hover,
      &.active:focus {
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%); }

      &:disabled {}
      &[disabled] {}
      &.disabled {
        opacity: .3;

        &:hover {
          cursor: default; } } }

    &.btn-link {
      // text-transform: uppercase
      font-size: 12px;
      padding: 12px 20px;
      color: $gray-color1;
      text-decoration: none;
      min-width: 0;

      &:hover {
        color: lighten($gray-color1, 5%); }

      &:focus,
      &:active,
      &.active,
      &:active:focus,
      &:active:hover,
      &.active:focus {
        color: darken($gray-color1, 10%); }

      &:disabled {}
      &[disabled] {}
      &.disabled {
        opacity: .3;

        &:hover {
          cursor: default; } } } } }

.page-container .footer .btn i {
  padding-left: 10px; }
