.welcome {
  p {
    margin: 2rem 0; }
  .title-page {
 }    // padding-top: 25vh

  .description {
    padding-right: 100px;
    font-size: .9rem;
    @media screen and (min-height: 800px) {
      font-size: 1.3rem; }
    a {
      text-decoration: underline; } } }
.introText {
  width: 82ch; }


.contact {
  .description {
    margin: 0; } }
