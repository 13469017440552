.switch-button {

  .switch {
    &.btn-sm {
      border-radius: 50px;
      width: 60px; }

    .btn-light {
      background-color: $gray-color1;
      color: $white-color; } } }
