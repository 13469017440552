.accordion-container {
  margin: 20px 0 30px 0;

  .accordion {
    .card {
      border-radius: 10px;

      .card-header {
        background-color: transparent;
        padding: 6px;
        font-size: 18px;
        font-weight: bold; } }

    .btn-link {
      color: $text-color;
      text-decoration: none;
      width: 100%;
      text-align: left;

      &:hover {
        text-decoration: none; }

      i {
        margin-left: 10px;
        float: right;
        position: relative;
        top: 4px; } } } }
